import React, { Component } from "react";
import { graphql } from "gatsby";

import Page from "../templates/page";

class Home extends Component {
  render() {
    //const currentPage = this.props.data.wordpressPage
    //console.log(this.props)
    return <Page data={this.props.data} />;
  }
}

export default Home;

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Home" }) {
      title
      slug
      ...Hero
      ...Info
      ...Grid
      ...Interstitial
      ...SEO
    }
  }
`;
